import React from 'react';

import Product from './Product';

function ProductGrid({ products }) {
  if (!products) return null;

  const categories = products.reduce((acc, item) => {
    item.categories.forEach(c => {
      if (!acc.find(a => a.slug === c.slug)) {
        acc.push(c);
      }
    });

    return acc;
  }, []);

  return categories.map(c => {
    return (
      <div key={c.slug}>
        <h2 className="text-white px-6 text-4xl">{c.name}</h2>
        <div className="flex flex-wrap">
          {products
            .filter(p => p.categories.find(cat => cat.slug === c.slug))
            .map(Product)}
        </div>
      </div>
    );
  });

  // return <div className="flex flex-wrap">
  //   {products.map(Product)}
  // </div>;
}

export default ProductGrid;
