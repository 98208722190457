module.exports = [
  {
    default: true,
    path: ``,
    label: `polski`,
    locale: `pl-PL`,
  },
  // {
  //   path: `de`,
  //   label: `German`,
  //   locale: `de-DE`,
  // },
];
