import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import PotockLink from '../components/PotockLink';
import LocaleLink from './LocaleLink';

function Product({ wordpress_id, name, price, slug, categories, images }) {
  // const [{ price }] = ;
  const [category] = categories;
  const path = category.slug + '/' + slug;
  const [image] = images;

  return (
    <article key={wordpress_id} className="p-6 w-full md:w-1/2 lg:w-1/3">
      <PotockLink
        to={`/sklep/${path}`}
        className="group no-underline w-full h-full flex"
      >
        <div className="bg-gainsboro rounded-lg cursor-pointer w-full overflow-hidden relative px-3 py-6 md:px-6">
          <Img
            fluid={image.localFile.childImageSharp.fluid}
            alt={name}
            title={name}
          />

          <div className="pt-3 md:pt-6 text-center">
            <p className="text-slategray font-bold text-lg group-hover:text-primary mb-1">
              {name}
            </p>
            <p className="text-lightgray text-sm">
              {price.toString().replace('.', ',')} zł
            </p>
          </div>
        </div>
      </PotockLink>
    </article>
  );
}

export default Product;
