import React from 'react';

import SEO from '../components/SEO';
import ProductGrid from '../components/ProductGrid';
// import FullScreenContainer from '../components/FullScreenContainer';
import Layout from '../components/Layout';

function ShopPage({
  data: {
    allWcProducts: { edges: allProducts },
  },
}) {
  const products = allProducts.map(n => n.node);

  return (
    <Layout fullPage={false} shop={true}>
      <SEO pageTitle="Sklep" />
      <div className="mb-6 pt-20 px-6">
        <h1 className="font-bold text-3xl md:text-6xl mb-3 text-white">
          Sklep
        </h1>

        <hr className="border-b border-gainsboro w-10" />
      </div>

      <ProductGrid products={products} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query AllProductsQuery {
    allWcProducts(sort: { fields: categories___menu_order }) {
      edges {
        node {
          name
          slug
          price
          wordpress_id
          categories {
            name
            slug
          }
          images {
            alt
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 800, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ShopPage;
